import {
  FollowButtonAction,
  FollowButton as FollowButtonComponent,
  getLoginDialogTitleAndDescription,
} from "@product/scmp-sdk";
import type {
  FollowButtonDisplay,
  FollowButtonLoadingRelatedProps,
  FollowButtonOutline,
  FollowButtonSize,
  FollowButtonVariant,
  FollowType,
} from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import qs from "qs";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import { useLoginDialogStateHelper } from "~/components/login-dialog/hooks";
import { gdprAtom } from "~/components/tracking/gdpr";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event, FollowButtonSource } from "~/components/tracking/google-analytics/types";
import { accountAtom } from "~/lib/account";

import type { TooltipProps } from "./follow-tooltip";
import { FollowTooltip } from "./follow-tooltip";
import { useUserFollow } from "./hooks";
import type { FollowQueryStringPayload } from "./types";
import { computeCompletionAction } from "./utils";

export type Props = {
  className?: string;
  disableClickTracking?: boolean;
  disabled?: boolean;
  display?: FollowButtonDisplay;
  entityIds: string[];
  isDisabledTooltip?: boolean;
  isTooltipFlipDisabled?: boolean;
  name: string;
  onClick?: (isActionFollow: boolean) => void;
  outline?: FollowButtonOutline;
  size?: FollowButtonSize;
  source?: FollowButtonSource;
  tooltipProps?: TooltipProps;
  type: FollowType;
  variant?: FollowButtonVariant;
} & FollowButtonLoadingRelatedProps;

export const FollowButton: FunctionComponent<Props> = ({
  className,
  disableClickTracking = false,
  disabled = false,
  display,
  entityIds,
  isDisabledTooltip = false,
  isLoadingEnabled,
  isTooltipFlipDisabled,
  loadingDelay,
  name,
  onClick,
  outline,
  size,
  source,
  tooltipProps = {
    position: "bottom-start",
  },
  type,
  variant,
}) => {
  const { checkIsFollowed, handleFollowAction } = useUserFollow();
  const { isLoggedIn } = useAtomValue(accountAtom);
  const { openLoginDialog } = useLoginDialogStateHelper();

  const handleFollowClick = (type: FollowType, entityIds: string[], campaignName: string) => {
    if (isLoggedIn) {
      handleFollowAction(entityIds, type, FollowButtonAction.Follow, () => {
        sendGATracking({
          action: computeCompletionAction(FollowButtonAction.Follow, type, source),
          category: "Notification Center",
          label: name,
        });
      });
    } else {
      const queryStringPayload: FollowQueryStringPayload = {
        action: {
          follow: {
            [type]: entityIds,
          },
        },
        source,
      };
      const accountUrl = new URL(window.location.href);
      accountUrl.search = qs.stringify(queryStringPayload);

      const { description, title } = getLoginDialogTitleAndDescription({
        campaignName,
        type,
      });

      openLoginDialog({
        description,
        destination: accountUrl.toString(),
        ga4CustomParameter: {
          follow_name: campaignName,
          follow_type: type,
          trigger_point: "follow",
        },
        title,
      });
    }
  };

  const handleUnfollowClick = (type: FollowType, entityIds: string[], campaignName: string) => {
    handleFollowAction(entityIds, type, FollowButtonAction.Unfollow, () => {
      sendGATracking({
        action: computeCompletionAction(FollowButtonAction.Unfollow, type, source),
        category: "Notification Center",
        label: campaignName,
      });
    });
  };

  const [isFollowingEveryEntity, setIsFollowingEveryEntity] = useState(false);
  useEffect(() => {
    const isEveryEntityFollowed = entityIds.every(entityId => checkIsFollowed(type, entityId));
    setIsFollowingEveryEntity(isEveryEntityFollowed);
  }, [entityIds, checkIsFollowed, type]);

  const handleClick = () => {
    if (isFollowingEveryEntity) {
      handleUnfollowClick(type, entityIds, name);
    } else {
      if (!disableClickTracking) {
        const event: Event = source
          ? {
              action: `Notification Center/Follow/${source}/Click` as const,
              category: "Notification Center",
            }
          : {
              action: `Notification Center/Follow/Click`,
              category: "Notification Center",
              label: location.href,
            };
        sendGATracking(event);
      }
      handleFollowClick(type, entityIds, name);
    }
    onClick?.(!isFollowingEveryEntity);
  };

  const { isAccepted } = useAtomValue(gdprAtom);

  return (
    <FollowTooltip
      {...tooltipProps}
      className={className}
      disabled={isFollowingEveryEntity || !isAccepted || isDisabledTooltip}
      isFlipDisabled={isTooltipFlipDisabled}
      name={name}
      type={type}
    >
      <FollowButtonComponent
        disabled={disabled}
        display={display}
        isFollowing={isFollowingEveryEntity}
        isLoadingEnabled={isLoadingEnabled}
        loadingDelay={loadingDelay}
        outline={outline}
        size={size}
        variant={variant}
        onClick={handleClick}
      />
    </FollowTooltip>
  );
};

FollowButton.displayName = "FollowButton";
