import { useDeepCompareEffect } from "@react-hookz/web";
import { useSetAtom } from "jotai";

import type { AdSlotState } from "~/components/app-bar/atoms";
import { appBarAdSlotAtom } from "~/components/app-bar/atoms";

export const useAppBarAdSlot = (state: AdSlotState) => {
  const setState = useSetAtom(appBarAdSlotAtom);
  useDeepCompareEffect(() => {
    setState(state);
    return () => setState(null);
  }, [state, setState]);
};
