import { atom } from "jotai";

import type { Props } from "~/components/advertisement/ad-slots/ad-slot";

import type { AppBarVariant } from "./helpers";

export type HeaderAdSlotSpec = Pick<
  Props,
  "adUnit" | "targeting" | "zone" | "autoRefreshOptions"
> & { sizes?: googletag.GeneralSize };

export type AdSlotState = {
  desktop: HeaderAdSlotSpec | null;
  mobile: HeaderAdSlotSpec | null;
} | null;

export const appBarAdSlotAtom = atom<AdSlotState>(null);

export type State = {
  scrollOverVariant: AppBarVariant | null;
} | null;

export const appBarAtom = atom<State>(null);
