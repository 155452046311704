import { FollowType, notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as FollowButtonProps } from "~/components/follow-button";
import { FollowButton } from "~/components/follow-button";
import { author } from "~/data";
import type { entityFollowButtonBase$key } from "~/queries/__generated__/entityFollowButtonBase.graphql";

export type Props = {
  reference: entityFollowButtonBase$key;
} & Omit<FollowButtonProps, "name" | "entityIds" | "type">;

export const EntityFollowButton: FunctionComponent<Props> = ({
  reference: reference_,
  ...props
}) => {
  const entity = useFragment(
    graphql`
      fragment entityFollowButtonBase on Base {
        __typename
        ... on Section {
          entityId
          name
        }
        ... on Author {
          entityId
          name
          disableFollow
        }
        ... on Topic {
          entityId
          name
          disableFollow
        }
      }
    `,
    reference_,
  );

  switch (entity.__typename) {
    case "Author":
      if (
        notEmpty(entity.disableFollow) ||
        author.advertisingPartner.entityId === entity.entityId
      ) {
        return null;
      }
      break;
    case "Topic":
      if (entity.disableFollow) return null;
      break;
    case "%other":
      return null;
  }

  const mapTypenameToFollowType = () => {
    switch (entity.__typename) {
      case "Author":
        return FollowType.Author;
      case "Topic":
        return FollowType.Topic;
      case "Section":
        return FollowType.Section;
    }
  };

  return (
    <FollowButton
      entityIds={[entity.entityId]}
      name={entity.name}
      type={mapTypenameToFollowType()}
      {...props}
    />
  );
};

EntityFollowButton.displayName = "EntityFollowButton";
