import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { Tooltip, tooltipClasses } from "~/components/common/tooltip";
import { ZIndexLayer } from "~/lib/styles";

export const ChildrenContainer = styled.div`
  position: relative;

  display: inline-flex;
  align-items: center;

  inline-size: fit-content;
`;

export const Container = styled.div`
  white-space: pre-line;
`;

export const StyledTooltip = styled(Tooltip)`
  z-index: ${ZIndexLayer.AppTooltip};

  & .${tooltipClasses.tooltip} {
    inline-size: 240px;
    padding: 16px;

    color: #ffffff;
    font-size: 16px;
    font-family: ${fontRobotoCondensed};
    letter-spacing: 0;

    border: 1px solid #001246;

    background-color: #001246;

    cursor: pointer;

    opacity: 0.95;
  }

  & .${tooltipClasses.arrow} {
    color: #001246;
  }
`;
