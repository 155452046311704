// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useCallback, useId } from "react";

const mutexAtomFamily = atomFamily((_key: string) => atom<string | null>(null));

export const useMutex = (key: string) => {
  const [value, setValue] = useAtom(mutexAtomFamily(`${key}-mutex`));
  const id = useId();

  const request = useCallback(() => {
    setValue(value => {
      if (value) return value;
      return id;
    });
  }, [id, setValue]);

  const release = useCallback(() => {
    setValue(value => {
      if (value !== id) return value;
      return null;
    });
  }, [id, setValue]);

  return {
    isGranted: value === id,
    release,
    request,
  };
};
