import type { PopperProps } from "@mui/material/Popper";
import type { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import type { StrictModifiers } from "@popperjs/core";
import { FollowType, theme, useFallbackStorageValue, useResponsive } from "@product/scmp-sdk";
import { useIntersectionObserver, useTimeoutEffect, useUnmountEffect } from "@react-hookz/web";
import type { FunctionComponent, ReactNode } from "react";
import { useEffect, useMemo, useRef } from "react";

import { useMutex } from "~/lib/hooks";

import { ChildrenContainer, Container, StyledTooltip } from "./styles";

export type TooltipProps = {
  position: Extract<MuiTooltipProps["placement"], "bottom-start" | "top-start">;
};

export type Props = {
  children?: ReactNode;
  className?: string;
  disabled: boolean;
  isFlipDisabled?: boolean;
  name: string;
  type: FollowType;
} & TooltipProps;

export const FollowTooltip: FunctionComponent<Props> = ({
  children,
  className,
  disabled,
  isFlipDisabled,
  name,
  position,
  type,
}) => {
  const childrenContainerReference = useRef<HTMLDivElement>(null);
  const { set: setHasShown, value: hasShown } = useFallbackStorageValue("IndicatorShow");

  const renderMessage = () => {
    const conjunctionMap = {
      [FollowType.Author]: "from",
      [FollowType.Section]: "about",
      [FollowType.Topic]: "about",
    };
    return (
      <Container onClick={() => setHasShown(true)}>
        Follow this {type} and get notified the next time we publish content {conjunctionMap[type]}{" "}
        <b>{name}</b>.
      </Container>
    );
  };

  // Track intersection if not yet show the tooltip
  const intersectionObserverEntry = useIntersectionObserver(
    hasShown ? null : childrenContainerReference,
  );
  const needToShowTooltip = useMemo(
    () =>
      /* Show the tooltip if localstorage doesn't have the key and disabled is false*/
      hasShown === null && !disabled && !!intersectionObserverEntry?.isIntersecting,
    [disabled, intersectionObserverEntry?.isIntersecting, hasShown],
  );

  // The first valid tooltip will be shown
  const { isGranted, release, request } = useMutex("follow-tooltip");
  useEffect(() => {
    if (!needToShowTooltip) return;
    request();
  }, [needToShowTooltip, request]);
  useUnmountEffect(() => {
    release();
  });

  // Auto hide after serval seconds, reset when it's not
  useTimeoutEffect(
    () => {
      setHasShown(true);
    },
    isGranted && needToShowTooltip ? 10_000 : undefined,
  );

  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);

  const modifiers: StrictModifiers[] = [
    {
      enabled: false,
      name: "flip",
    },
    {
      name: "offset",
      options: {
        offset: isMobile ? [-180, 0] : [0, 0],
      },
    },
  ];

  const popperProps: Partial<PopperProps> = {
    disablePortal: true,
    popperOptions: {
      modifiers: isFlipDisabled ? modifiers : undefined,
    },
  };

  return (
    <StyledTooltip
      open={isGranted && needToShowTooltip}
      placement={position}
      PopperProps={popperProps}
      title={renderMessage()}
      arrow
    >
      <ChildrenContainer className={className} ref={childrenContainerReference}>
        {children}
      </ChildrenContainer>
    </StyledTooltip>
  );
};

FollowTooltip.displayName = "FollowTooltip";
