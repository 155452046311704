import { notEmpty } from "@product/scmp-sdk";
import axios from "axios";
import http from "http";
import https from "https";

import { getGoogleAnalyticsClientId } from "~/components/tracking/google-analytics/apis";
import { config, section as sectionData } from "~/data";

import { AiEnginePaths, AiFallbackPaths } from "./consts";
import { AiEngineDataType } from "./enums";
import type { AiResponse, FetchDataParameters, MapperInput, SectionAiNameMapping } from "./types";

const instance = axios.create({
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
});

const mapper = (input: MapperInput) => ({
  a: input.entityId,
  aut: input.authorIds?.join(","),
  dpuuid: input.userUuid,
  ex: input.excludeIds?.join(","),
  ex_sections_prefix: input.excludedSections?.join(","),
  ex_type_prefix: input.excludedArticleTypes?.join(","),
  gcid: input.gaClientId,
  in_sections_prefix: input.includedSections?.join(","),
  limit: input.limit?.toString(),
  ranking: input.ranking,
  sc: input.sc,
  sec: input.sectionEntityIds
    ?.map(
      id =>
        (Object.values(sectionData) as SectionAiNameMapping[]).find(
          section => section.entityId === id,
        )?.aiEngineName,
    )
    ?.filter(aiEngineName => notEmpty(aiEngineName))
    ?.join(","),
  topic: input.topicIds?.join(","),
});

export const fetchAiEngineData = async <P extends AiEngineDataType, R = AiResponse[P]>({
  type,
  ...query
}: FetchDataParameters<P>) => {
  const url = new URL(AiEnginePaths[type], config.general.aiEngineHost);
  const gaClientId = await getGoogleAnalyticsClientId();
  const queryString = mapper({ ...query, gaClientId });

  Object.entries(queryString).forEach(([key, value]) => {
    if (!notEmpty(value)) return;
    url.searchParams.set(key, `${value}`);
  });

  try {
    return instance.get<R>(url.toString(), { timeout: 10_000 });
  } catch {
    const path =
      type === AiEngineDataType.OnBoard
        ? `${AiFallbackPaths[type]}/${queryString.sc ?? ""}.json`
        : AiFallbackPaths[type];
    const url = new URL(path, config.general.aiEngineFallbackHost);
    return instance.get<R>(url.toString(), { timeout: 10_000 });
  }
};

export const fetchAiEngineDataV2 = async <P extends AiEngineDataType, R = AiResponse[P]>({
  type,
  ...data
}: FetchDataParameters<P>) => {
  /** TODO:
   * Let's use the production host for now until the data team fixes the issues in the development environment:
   *
   * - Handle empty results properly.
   * - Support date range filtering to exclude the latest article from production.
   */
  const url = new URL(AiEnginePaths[type], "https://data-recommendation-api.scmp.com");
  const gaClientId = await getGoogleAnalyticsClientId();
  const postData = mapper({ ...data, gaClientId });

  try {
    return instance.post<R>(url.toString(), postData, { timeout: 10_000 });
  } catch {
    const path =
      type === AiEngineDataType.OnBoard
        ? `${AiFallbackPaths[type]}/${postData.sc ?? ""}.json`
        : AiFallbackPaths[type];
    const url = new URL(path, config.general.aiEngineFallbackHost);
    return instance.get<R>(url.toString(), { timeout: 10_000 });
  }
};
