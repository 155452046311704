import Slide from "@mui/material/Slide";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useDeepCompareMemo } from "@react-hookz/web";
import { useScroll, useThrottleEffect } from "ahooks";
import type { FunctionComponent } from "react";
import { useState } from "react";

import type { Props as AdSlotProps } from "~/components/advertisement/ad-slots/ad-slot";
import { DynamicAdSlot } from "~/components/advertisement/ad-slots/ad-slot/dynamics";
import { useTakeoverBottomAdSlot } from "~/components/advertisement/takeover-ad-slot/hooks";
import type { TypeMagazinesStyleVariant } from "~/components/section/section-style/const";

import CloseIcon from "./icon-close.svg";
import { AdSlotContainer, CloseButton, Container } from "./styles";

type Props = {
  adSlotProps?: Pick<AdSlotProps, "targeting" | "zone">;
  variant?: TypeMagazinesStyleVariant | "home";
};

export const TakeoverAdSlot: FunctionComponent<Props> = ({ adSlotProps, variant }) => {
  const isDesktopUp = useResponsive(theme.breakpoints.up("desktop"));
  const { isEnableTakeoverBottomAdSlot, takeoverAdSlotValue } = useTakeoverBottomAdSlot({
    adSlotProps,
    variant,
  });
  const [isClose, setIsClose] = useState(false);

  const [isAdVisible, setIsAdVisible] = useState(false);
  const scrollPosition = useScroll();

  useThrottleEffect(
    () => {
      if (scrollPosition && scrollPosition?.top > (isDesktopUp ? 300 : 200)) {
        const { clientHeight, scrollHeight } = document.documentElement;
        const isAtBottom = scrollPosition.top + clientHeight >= scrollHeight;
        if (isAtBottom) {
          setIsAdVisible(false);
          return;
        }
        setIsAdVisible(true);
        return;
      }
      setIsAdVisible(false);
    },
    [scrollPosition?.top],
    { wait: 200 },
  );

  const responsiveAdSlot = useDeepCompareMemo(() => {
    if (!takeoverAdSlotValue) return null;

    return isDesktopUp ? (
      <DynamicAdSlot {...takeoverAdSlotValue} sizes={[[970, 90], [728, 90], "fluid"]} />
    ) : (
      <DynamicAdSlot {...takeoverAdSlotValue} sizes={[[300, 50], [320, 50], "fluid"]} />
    );
  }, [takeoverAdSlotValue, isDesktopUp]);

  if (!isEnableTakeoverBottomAdSlot || isClose) return null;

  return (
    <Slide direction="up" in={isAdVisible} timeout={300}>
      <Container>
        <CloseButton onClick={() => setIsClose(true)}>
          <CloseIcon />
        </CloseButton>
        <AdSlotContainer $height={isDesktopUp ? 90 : 50}>{responsiveAdSlot}</AdSlotContainer>
      </Container>
    </Slide>
  );
};

TakeoverAdSlot.displayName = "TakeoverAdSlot";
